// code copy from ERP
var Verification = {
  //验证邮箱
  IsEmail: function(text) {
    var reg = /^([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,4}$/;
    if (reg.test(text)) {
      return true;
    } else {
      return false;
    }
  },
  EmailText: '请输入正确的邮箱',
  //验证手机
  IsPhoneNumber: function(text) {
    var reg = /^1[0-9]{10}$/;
    if (reg.test(text)) {
      return true;
    } else {
      return false;
    }
  },
  PhoneNumberInfo: "请输入正确的手机号",
  //验证是否为数字
  IsNum: function(text) {
    var age = /^[0-9]*$/
    if (age.test(text)) {
      return true;
    } else {
      return false;
    }
  },
  IsNumTxet: "请输入正整数",
  //校验包含小数点的数字
  isNUberFolat: function(text) {
    var floatNUm = /^[+]{0,1}(\d+)$|^[+]{0,1}(\d+\.\d+)$/
    if (floatNUm.test(text)) {
      return true;
    } else {
      return false;
    }
  },
  isNUberFolatText: '请输入数字',
  //验证车牌好
  IsTransportation: function(text) {
    if (text.length == 7) {
      var regExp = /[冀豫云辽黑湘皖鲁苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼渝京津沪新京军空海北沈兰济南广成使领]{1}[A-Z]{1}[A-Z0-9]{5}/;
      return regExp.test(text);
    } else {
      return false;
    }
  },
  //密码验证（必须包含大、小写字母和数字并长度在6-30）
  VerifyPassword: function(data) {
    //var reg = /^[a-zA-Z0-9]{6,30}$/; //6-20位数字、字母组合
    var reg = /^(?=.*[0-9].*)(?=.*[A-Z].*)(?=.*[a-z].*).{6,20}$/;
    if (reg.test(data)) {
      return true;
    }
    return false;
  },
  PasswordInfo: "必须包含大、小写字母和数字并长度在6-30",
  //验证身份证号码
  VerifyIDCard: function(data) {
    var reg = /^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/;
    if (reg.test(data)) {
      return true;
    } else {
      return false;
    }
  },
	IDCardInfo: "请输入正确的身份证号",
  //数组去重；
  uniq: function(array) {
    var temp = []; //一个新的临时数组
    for (var i = 0; i < array.length; i++) {
      if (temp.indexOf(array[i]) == -1) {
        temp.push(array[i]);
      }
    }
    return temp;
  },
  //是否函数
  IsFunction: function(funcName) {
    try {
      if (typeof(eval(funcName)) == "function") {
        return true;
      }
    } catch (e) {
      return false;
    }
    return false;
  },
  IsDate: function(datastr) {
    if (datastr.length == 10) {
      var reg = /^(\d{1,4})(-)(\d{1,2})\2(\d{1,2})$/;
      var r = datastr.match(reg);
      if (r == null) {
        return false;
      } else {
        //除去不正确时间如1234-45-56
        var d = new Date(r[1], r[3] - 1, r[4]);
        var c = (d.getFullYear() == r[1] && (d.getMonth() + 1) == r[3] && d.getDate() == r[4]);
        if (!c) {
          return false;
        }
      }
    }
    return true;
  },
  IsDateTime: function(datatimestr) {
    var reg = /^[1-9]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])\s+(20|21|22|23|[0-1]\d):[0-5]\d:[0-5]\d$/;
    var regExp = new RegExp(reg);
    if (!regExp.test(datatimestr)) {
      return false;
    }
    return true;
  },
  GetSysDate: function() {
    var date = new Date();
    var seperator1 = "-";
    var seperator2 = ":";
    var month = date.getMonth() + 1;
    var strDate = date.getDate();
    if (month >= 1 && month <= 9) {
      month = "0" + month;
    }
    if (strDate >= 0 && strDate <= 9) {
      strDate = "0" + strDate;
    }
    var currentdate = date.getFullYear() + seperator1 + month + seperator1 + strDate;
    return currentdate;
  },
  addDate: function(date, days) {
    if (days == undefined || days == '') {
      days = 1;
    }
    var date = new Date(date);
    date.setDate(date.getDate() + days);
    var month = date.getMonth() + 1;
    var day = date.getDate();
    return date.getFullYear() + '-' + HHutil.getFormatDate(month) + '-' + HHutil.getFormatDate(day);
  },
  getFormatDate: function(arg) {
    if (arg == undefined || arg == '') {
      return '';
    }
    var re = arg + '';
    if (re.length < 2) {
      re = '0' + re;
    }
    return re;
  },
  //计算两个日期差 格式：2017-10-13
  DateDiff: function(sDate1, sDate2) {
    var dateSpan, tempDate, iDays;
    sDate1 = Date.parse(sDate1);
    sDate2 = Date.parse(sDate2);
    dateSpan = sDate2 - sDate1;
    dateSpan = Math.abs(dateSpan);
    iDays = Math.floor(dateSpan / (24 * 3600 * 1000));
    return iDays;
  },
  //获取当前月第一天和最后一天
  GetMonth: function() {
    var ThisMonth = {};
    var firstDate = new Date();
    firstDate.setDate(1); //第一天
    var endDate = new Date(firstDate);
    endDate.setMonth(firstDate.getMonth() + 1);
    endDate.setDate(0);
    start_date = js_date_time(new Date(firstDate));
    end_date = js_date_time(new Date(endDate));
    ThisMonth.start_date = start_date;
    ThisMonth.end_date = end_date;
    return ThisMonth;
  },
  //得到今天昨天日期
  GetDate: function() {
    var ThisDate = {};
    var day1 = new Date();
    day1.setDate(day1.getDate() - 1);
    var s1 = js_date_time(new Date(day1));
    start_date = js_date_time(new Date(s1));
    end_date = js_date_time(new Date());
    ThisDate.start_date = start_date;
    ThisDate.end_date = end_date;
    return ThisDate;
  },
  //取得系统时间 "2017-10-10 10:10:10"
  GetSysDatetime: function() {
    var date = new Date();
    var seperator1 = "-";
    var seperator2 = ":";
    var month = date.getMonth() + 1;
    var strDate = date.getDate();
    if (month >= 1 && month <= 9) {
      month = "0" + month;
    }
    if (strDate >= 0 && strDate <= 9) {
      strDate = "0" + strDate;
    }
    var h = date.getHours();
    var m = date.getMinutes();
    var s = date.getSeconds();
    if (h >= 0 && h <= 9) {
      h = "0" + h;
    }
    if (m >= 0 && m <= 9) {
      m = "0" + m;
    }
    if (s >= 0 && s <= 9) {
      s = "0" + s;
    }
    var currentdate = date.getFullYear() + seperator1 + month + seperator1 + strDate +
      " " + h + seperator2 + m + seperator2 + s;
    return currentdate;
  },
  //取得系统日期 "2017-10-10"
  GetSysDate: function() {
    var date = new Date();
    var seperator1 = "-";
    var seperator2 = ":";
    var month = date.getMonth() + 1;
    var strDate = date.getDate();
    if (month >= 1 && month <= 9) {
      month = "0" + month;
    }
    if (strDate >= 0 && strDate <= 9) {
      strDate = "0" + strDate;
    }
    var currentdate = date.getFullYear() + seperator1 + month + seperator1 + strDate;
    return currentdate;
  }
};
// Array.prototype.diff = function (a) {
//     return this.filter(function (i) {
//         return a.indexOf(i) < 0;
//     });
// };
//格式化日期
// Date.prototype.Format = function (fmt) { //author: meizz
//     var o = {
//         "M+": this.getMonth() + 1, //月份
//         "d+": this.getDate(), //日
//         "H+": this.getHours(), //小时
//         "m+": this.getMinutes(), //分
//         "s+": this.getSeconds(), //秒
//         "q+": Math.floor((this.getMonth() + 3) / 3), //季度
//         "S": this.getMilliseconds() //毫秒
//     };
//     if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (this.getFullYear() + "").substr(4 - RegExp.$1.length));
//     for (var k in o)
//         if (new RegExp("(" + k + ")").test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
//     return fmt;
// }

//格式化日期加时间
var DateTimeOperation = {
  Formatter: function(date) {
    return DateBoxOperation.Formatter(date) + " " + TimeSpinnerOperation.Formatter(date);
  },
  Parser: function(string) {
    if (!string) {
      return new Date();
    }
    var date = string.split(" ")[0];
    var year = parseInt(date.split("-")[0], 10);
    var month = parseInt(date.split("-")[1], 10);
    var day = parseInt(date.split("-")[2], 10);
    var time = string.split(" ")[1];
    var hour = parseInt(time.split(":")[0], 10);
    var minute = parseInt(time.split(":")[1], 10);
    var second = parseInt(time.split(":")[2], 10);

    if (!isNaN(year) && !isNaN(month) && !isNaN(day) && !isNaN(hour) && !isNaN(minute) && !isNaN(second)) {
      return new Date(year, month - 1, day, hour, minute, second);
    } else {
      return new Date();
    }
  }
};

var TimeSpinnerOperation = {
  Formatter: function(date) {
    var hour = ("00").substring(0, 2 - date.getHours().toString().length) + date.getHours().toString();
    var minute = ("00").substring(0, 2 - date.getMinutes().toString().length) + date.getMinutes().toString();
    var second = ("00").substring(0, 2 - date.getSeconds().toString().length) + date.getSeconds().toString();
    return hour + ":" + minute + ":" + second;
  },
  FormatterNoSecond: function(date) {
    var hour = ("00").substring(0, 2 - date.getHours().toString().length) + date.getHours().toString();
    var minute = ("00").substring(0, 2 - date.getMinutes().toString().length) + date.getMinutes().toString();
    return hour + ":" + minute;
  },
  Parser: function(string) {
    if (!string) return new Date(1900, 0, 1, 0, 0, 0);
    var temp = string.split(":");
    return new Date(1900, 0, 0, parseInt(temp[0], 10), parseInt(temp[1], 10), parseInt(temp.length == 2 ? "00" :
      temp[2], 10));
  }
};

//格式化日期
var DateBoxOperation = {
  Formatter: function(date) {
    var year = ("0000").substring(0, 4 - date.getFullYear().toString().length) + date.getFullYear().toString();
    var month = ("00").substring(0, 2 - (date.getMonth() + 1).toString().length) + (date.getMonth() + 1).toString();
    var day = ("00").substring(0, 2 - date.getDate().toString().length) + date.getDate().toString();
    return year + "-" + month + "-" + day;
  },
  Parser: function(string) {
    if (!string) {
      return new Date();
    }
    var date = string.split(" ")[0];
    var year = parseInt(date.split("-")[0], 10);
    var month = parseInt(date.split("-")[1], 10);
    var day = parseInt(date.split("-")[2], 10);

    if (!isNaN(year) && !isNaN(month) && !isNaN(day)) {
      return new Date(year, month - 1, day);
    } else {
      return new Date();
    }
  }
};

export default {
  Verification,
  DateTimeOperation,
  TimeSpinnerOperation,
  DateBoxOperation
}
