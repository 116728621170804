<template>
	<div :class="themeClass" class="contBox margin-t-10 bg-fff  enterprise">
		<div class="newPersonbtn-Boxx">
			<div class="newPersonbtn-title font-size18 font-weight700">我的资料</div>
		</div>
    <div class="text-center font-size16 margin-b-15 flex-row-center-center">
					<span class="iconfont icon-biaodan xinzengIcon color-theme font-size25"></span>
					实名认证
		</div>
		<div class="newPersonbtn-Box">
			<el-form :model="formData" :rules="rules" ref="formData"  label-width="180px" class="demo-ruleForm"
				v-loading='formLoading'>
				<div class="info-top">
					<p class="infoti padding-l-10">
						以下信息请与营业执照信息一致
					</p>
					<el-form-item style="margin-top:175px" label="名称" prop="corporateName">
						<el-input v-model="formData.corporateName" placeholder="请输入公司名称" :disabled="!isEditOne||!isUpload"></el-input>
					</el-form-item>
					<el-form-item label="统一信用代码" prop="UnifiedCreditCode">
						<el-input v-model="formData.UnifiedCreditCode" :maxlength='18' placeholder="请输入统一信用代码"
							style="width: 70%;" :disabled="!isEditOne||!isUpload">
						</el-input>
					</el-form-item>
					<el-form-item label="住所" prop="address">
						<el-input v-model="formData.address" placeholder="请输入营业执照住所完整内容" autocomplete="new-password" :disabled="!isEditOne||!isUpload"></el-input>
					</el-form-item>
				</div>
				<div class="info-top" style="margin-top: 25px;margin-bottom: 20px;">
					<p class="infoti padding-l-10">
						以下信息请与发票信息一致
					</p>
					<el-form-item  label="发票类型" prop="fInvoiceClassID" v-if='false'>
						<el-select v-model="formData.fInvoiceClassID" placeholder="请选择发票种类" ref="fInvoiceClassID"
							style="width: 100%;" @change="selectInvoiceClass">
							<el-option v-for="(item,index) in invoiceClassList" :key="index"
								:label="item.fInvoiceClassName" :value="item.fInvoiceClassID"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item style="margin-top:175px" label="发票类型" prop="fInvoiceClassID">
						<div style="display: flex;width: 90%;justify-content: space-between;">
							<div  class="fapiaoType" :class="formData.fInvoiceClassID == item.fInvoiceClassID?'colortype':''" style="position: relative;" v-for="(item,index) in invoiceClassList" :key="index" @click='newselectInvoiceClass(item.fInvoiceClassID)'>
								<span :style="formData.fInvoiceClassID == item.fInvoiceClassID ? 'color: #0173FE;' : ''">{{item.fInvoiceClassName}}</span>
								<div  v-if='formData.fInvoiceClassID == item.fInvoiceClassID' class="gouhao"></div>
								<div  v-if='formData.fInvoiceClassID == item.fInvoiceClassID' class='icongouhao'><i  class="el-icon-check" style="font-size: 12px;color:white"></i></div>
							</div>
						</div>
					</el-form-item>
					<el-form-item label="银行账号" prop="bankAccount">
						<el-input v-model="formData.bankAccount" @input="bankAccountReplace" maxlength='32'
							placeholder="请输入银行账号"></el-input>
					</el-form-item>
					<el-form-item label="开户行" prop="fBankTypeName">
						<el-input v-model="formData.fBankTypeName"  placeholder="请输入开户行名称"></el-input>
					</el-form-item>
					<el-form-item label="公司电话" prop="companyNumber">
						<el-input v-model="formData.companyNumber" @input='companyNumberReplace' autocomplete="new-password"
							placeholder="请跟发票信息电话一致"></el-input>
					</el-form-item>
				</div>
				<el-form-item label="业务服务区域" class="areaBox" prop="fGovernmenAreaID">
					<selectArea v-if="hasGovernmenArea" v-model="formData.fGovernmenAreaID"></selectArea>
					<selectArea v-if="!hasGovernmenArea&&formData.fGovernmenAreaID.length>0"
						v-model="formData.fGovernmenAreaID"></selectArea>
					<div class="font-size12 font-color-FF9900 margin-t-4" style="line-height: normal;">
						* 提示：平台将根据您所选择的业务服务区域，为您推送相关区域内的公告信息
					</div>
				</el-form-item>
				<el-form-item label="邮箱" prop="fEmail" v-if="fIsShow">
					<el-input v-model="formData.fEmail" placeholder="请输入邮箱地址" autocomplete="new-password"></el-input>
					<div class="font-size12 font-color-FF9900 margin-t-4" style="line-height: normal;">
						* 提示：
						<span>邮箱可接收客户发票递送等信息</span>
					</div>
				</el-form-item>
				<el-form-item v-if="getUserInfo.fCommerceTypeID=='0'" label="邀请码" prop="finviterCode">
					<el-input v-model="formData.finviterCode" placeholder="请输入邀请码，若无人邀请可不用填写"></el-input>
				</el-form-item>
        <!-- 这里做数据同步没有数据就是清除 -->
        <div v-if="uploadRule.length>0">
				<el-form-item v-for="(item, index) in uploadRule" :key="index" :label="item.rule.fRuleName"  class='testClass' :class="[changeclass(item)]" :required="item.rule.fIsNecessary == 1 ? true : false">
					<div v-if="item.rule.fRuleName=='授权委托书'">
						<el-button class="color-theme border-theme" @click="submitInform(3)" :disabled="!isEdit"
							:loading="buttonLoadingXZ">生成授权委托书</el-button>
						<div class="font-size12 font-color-FF9900">* 提示：请下载本商城提供的委托书模板并加盖公章上传。</div>
					</div>
					<div class="padding-10 border-F2F2F2">
						<UploadImgList :rules="item.rule" :ISconfirm="ISconfirm" :uploadToken="uploadToken[index]" class="uploadimglist"
							:limit="1" :accept="limitTypeArr[index]" v-model="imgListName[index]" uploadType="image" @success="uploadSuccess"/>
					</div>
					<div class="font-size12 font-color-FF9900 margin-t-4" style="line-height: normal;"
						v-if="limitTypeArr[index]||item.rule.fMaxSize">
						* 提示：
						<span>格式为{{item.rule.fFileType}}</span>
						<span v-if="item.rule.fMaxSize">,大小不超过{{ item.rule.fMaxSize }}MB</span>
						<!-- <span v-if="item.rule.fMaxWidth">,宽度不能超过{{ item.rule.fMaxWidth }}px</span>
						<span v-if="item.rule.fMaxHeight">,高度不能超过{{ item.rule.fMaxHeight }}px</span> -->
					</div>
				</el-form-item>
      </div>
				
				<el-form-item label-width="140px">
					<el-button class="background-color-theme" @click="submitInform(1)" :disabled="!isEdit"
						:loading="buttonLoadingTJ">{{buttonText}}</el-button>
					<el-button class="background-color-theme" @click="submitInform(2)" :disabled="!isEdit"
						:loading="buttonLoadingZC">暂存</el-button>
				</el-form-item>
        <div style="color:#C0C4CC; width: 550px;">有企业营业执照(含个体工商户)的用户请注册。权益如下：做企业实名认证；作为卖家身份开店；作为买家身份采购。</div>
			</el-form>
		</div>
		<div class="bg-box">
    </div>
	</div>
</template>
<script>
	import {
		mapGetters
	} from 'vuex';
	import selectArea from '@/components/selectArea/selectArea.vue'
	import UploadImgList from '@/components/upload/UploadImgList';
	import Verification from '@/utils/Verification';
	export default {
		name: "SelectArea",
		components: {
			selectArea,
			UploadImgList
		},
		data() {
			const fEmailchange = (rule, value, callback) => {
				if (!Verification.Verification.IsEmail(value)) {
					callback(new Error(Verification.Verification.EmailText));
				} else {
					callback();
				}
			};
			const validateIDCard = (rule, value, callback) => {
				if (!Verification.Verification.VerifyIDCard(value)) {
					callback(new Error(Verification.Verification.IDCardInfo));
				} else {
					callback();
				}
			};
			const governmenArea = (rule, value, callback) => {
				if (!value[0]) {
					callback(new Error('请选择业务服务区域'));
				} else {
					callback();
				}
			};
			const UnifiedCreditCode = (rule, value, callback) => {
				if (value != '') {
					if (value.length < 18) {
						callback(new Error('统一信用代码不正确请重新输入'));
					}
					callback();
				} else {
					callback();
				}
			};
			return {
        ISconfirm: true,//是否展开提示
				getYZ: "获取验证码",
				miao: '',
				formData: {
					corporateName: "", //公司名称
					UnifiedCreditCode: "", //统一信用代码
					fGovernmenAreaID: [], //选择行政区域ID
					address: "", //详细地址
					bankAccount: "", //银行账号
					fBankTypeName: "", //开户行
					companyNumber: "", //公司电话
					fInvoiceClass: '', //发票类型
					fInvoiceClassID: '', //发票类型ID
					finviterCode: '', //邀请码
					fEmail: '' //邮箱
				},
				rules: {
					corporateName: [{
						required: true,
						message: '请输入公司名称',
						trigger: 'blur'
					}],
					UnifiedCreditCode: [{
						required: true,
						message: '请输入统一信用代码',
						trigger: 'blur'
					}, {
						validator: UnifiedCreditCode,
						trigger: "blur"
					}],
					fGovernmenAreaID: [{
						required: true,
						validator: governmenArea,
						trigger: "change"
					}],
					address: [{
						required: true,
						message: '请输入营业执照住所完整内容',
						trigger: 'blur'
					}],
					bankAccount: [{
						required: true,
						message: '请输入银行账号',
						trigger: 'blur'
					}, {
						min: 9,
						message: '银行账号不少于9位',
						trigger: 'blur'
					}],
					fBankTypeName: [{
						required: true,
						message: '请输入开户行名称',
						trigger: 'blur'
					}],
					fInvoiceClassID: [{
						required: true,
						message: '请选择发票类型',
						trigger: 'change'
					}],
					companyNumber: [{
						required: true,
						message: '请输入公司电话',
						trigger: 'blur'
					},{
						required: true,
						pattern: /^((0\d{2,3}-\d{7,8})|(1[3456789]\d{9}))$/,
						message: '请输入正确的11位手机号码或使用-的座机号码',
						trigger: "blur"
					}],
					fEmail: [{
						required: true,
						message: '请输入邮箱地址',
						trigger: 'blur'
					}, {
						required: true,
						pattern: /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/,
						message: '请输入正确邮箱',
						trigger: "blur"
					}],
				},
        isUpload: false, //身份证是否上传
				invoiceClassList: [], //发票类型
				uploadRule: [], //上传图片规则
				uploadToken: [], //上传图片token
				limitTypeArr: [], //上传图片格式规则
				imgListName: [], //上传图片名
				uploadRule1: [], //上传图片规则
				// uploadToken1: [], //上传图片token
				limitTypeArr1: [], //上传图片格式规则
				imgListName1: [], //上传图片名
				action: 'http://192.168.201.34:51045/api/henghe/blob/upload', //上传图片路径
				// action:'https://www.henghesoft.com:8444/api/henghe/blob/upload', //上传图片路径
				fInvoiceClassID: 2, //发票类型ID公司默认为2
				isEdit: true, //是否可以编辑
				userData: {},
				buttonText: '提交审核',
				isEditOne: true, //编辑能否修改
				isCommit: false, //是否立即提交
				formLoading: false, //表单加载
				hasGovernmenArea: false, //是否有行政区域
				buttonLoadingZC: false, //暂存按钮加载
				buttonLoadingTJ: false, //提交审核按钮加载
				buttonLoadingXZ: false, //生成授权委托书按钮加载
				fIsShow : false,
			}
		},
		computed: {
			...mapGetters(['getThemeName', 'getUserInfo']),
			themeClass() {
				return `theme-${this.getThemeName}`;
			}
		},
		mounted() {
			this.userInfo = JSON.parse(sessionStorage.getItem("setUserInfo"))
			this.fVerifiedStatus = this.userInfo.fVerifiedStatus
			this.getInvoiceClass()
			this.getLegalEntity()
		},
		methods: {
			//上传成功识别营业执照
			uploadSuccess(e, url) {
				// console.log('上传成功', e, url);
        if(e[0].fAnnexTypeID!='502'){
          return
        }
				let param = {
					imageUrl: url + e[0].fPath
				}
				this.ApiRequestPost('/api/mall/tencent-cloud/ocr-biz-license-by-image-url', param)
					.then(res => {
						console.log(res);
						if (res.isSucceed) {
              this.formData.corporateName = res.name
              this.formData.address = res.address
			  this.formData.UnifiedCreditCode = res.regNum
						} else {
							this.$message('营业执照识别失败，请手动输入');
						}
						this.isUpload = true;
					}, error => {
						this.isUpload = true;
					});
			},
      changeclass(val){
        // [item.rule.fAnnexTypeID == '502' && item.rule.fBillTypeID == '200016001' ? 'trueClass rulecontent' : item.rule.fAnnexTypeID == '208'  && item.rule.fBillTypeID == '200016001'? 'manClass rulecontent'
        // console.log(val.rule.fRuleName,123132,val.rule)
        // if()
        // 一般纳税人资质证明
        if(val.rule.fAnnexTypeID == '208'  && val.rule.fBillTypeID == '200016019'){
          // console.log(val.rule.fRuleName,222)
          return 'manClass rulecontent'
        }
        if(val.rule.fAnnexTypeID == '208'  && val.rule.fBillTypeID == '200016001'){
          // console.log(val.rule.fRuleName,222)
          return 'manClass rulecontent'
        }
        // 营业执照
        if(val.rule.fAnnexTypeID == '502'  && val.rule.fBillTypeID == '200016019'){
          // console.log(val.rule.fRuleName,33)
          return 'trueClass rulecontent'
        }
        if(val.rule.fAnnexTypeID == '502'  && val.rule.fBillTypeID == '200016001'){
          // console.log(val.rule.fRuleName,33)
          return 'trueClass rulecontent'
        }
      },
	  //点击发票类型
	  newselectInvoiceClass(val){
        // 这个放到前面是发票规则
        //这里原本要调用 getAdjustBillTypeAnnexRule这个方法 但是在编辑的时候调用了所以这个方法去除了
        this.fInvoiceClassID  = val
		this.getUserCurrentBillInvoiceClass()
        this.formData.fInvoiceClassID = val
		this.$forceUpdate()
	  },
      //这里是切换在调取一次编辑变更资料接口接口 不然切换会丢失数据
      getUserCurrentBillInvoiceClass() {
				this.formLoading = true;
				this.ApiRequestPostNOMess('api/mall/ebcustomer/adjust-bill/get-current-bill')
					.then(
						res => {
							console.log('用户资料', res);
							if (res.obj) {
								this.userData = res.obj;
								if (this.userData.fCommerceTypeID == 2) {
									this.isUpload = true;
									if (this.getUserInfo.fCommerceTypeID == 2) {
										this.buttonText = '变更资料';
										this.isEditOne = true;
									}
									this.formData.corporateName = this.userData.fCustomerName; //公司名
									this.formData.fBusiness = this.userData.fBusiness; //行业性质
									this.formData.fBusinessID = this.userData.fBusinessID + ''; //行业性质ID
									this.formData.fEmail = this.userData.fEmail + ''; //邮箱
									//行政区域数据处理
									if (this.userData.fFullIDPath != '' && this.userData.fFullIDPath != null && this
										.userData.fFullIDPath !=
										undefined) {
										let governmenAreaArr = this.userData.fFullIDPath.split("\\");
										//过滤数组中的空字符
										governmenAreaArr = governmenAreaArr.filter((s) => {
											return s && s.trim();
										});
										this.formData.fGovernmenAreaID = governmenAreaArr
										// console.log('this.formData.fGovernmenAreaID', this.formData.fGovernmenAreaID);
									} else {
										this.hasGovernmenArea = true;
									}
									this.formData.UnifiedCreditCode = this.userData.fIdentityCode; //统一信用代码
									this.formData.fInvoiceClass = this.userData.fInvoiceClassName; //发票类型
                  //注释这一句让他走选中的数据
									// this.formData.fInvoiceClassID = this.userData.fInvoiceClassID + ''; //发票类型ID
									// this.fInvoiceClassID = this.userData.fInvoiceClassID + '';
									this.formData.companyNumber = this.userData.fPhone; //公司电话
									this.formData.bankAccount = this.userData.fBankAccounts; //银行卡号
									this.formData.fBankTypeName = this.userData.fBankNames; //开户行
									//地址数据处理
									this.formData.address = this.userData.fAddress;
									this.formData.nameOfLegalPerson = this.userData.fLegalPerson; //法人姓名
									this.formData.IDCardNumber = this.userData.fLegalPersonCard; //法人身份证号
									this.formData.fRemark = this.userData.fRemark; //备注
									this.formData.fInvoiceClass = this.userData.fInvoiceClassName; //发票类型名
									this.formData.finviterCode = this.userData.fInvitationCode; //邀请码
									this.formData.fEmail = this.userData.fEmail; //邮箱
									// console.log(this.formData);
									this.$forceUpdate()
								} else {
									this.hasGovernmenArea = true;
								}
							} else {
								this.hasGovernmenArea = true;
							}
							this.getAdjustBillTypeAnnexRule();
							// this.getAdjustBillTypeAnnexRule1();
						},
						error => {
							this.getAdjustBillTypeAnnexRule();
							// this.getAdjustBillTypeAnnexRule1();
						}
					);
			},
			//获取用户完善资料
			getUserCurrentBill() {
				this.formLoading = true;
				this.ApiRequestPostNOMess('api/mall/ebcustomer/adjust-bill/get-current-bill')
					.then(
						res => {
							console.log('用户资料', res);
							if (res.obj) {
								this.userData = res.obj;
								if (this.userData.fCommerceTypeID == 2) {
                  this.isUpload = true;
									if (this.getUserInfo.fCommerceTypeID == 2) {
										this.buttonText = '变更资料';
										this.isEditOne = true;
									}
									this.formData.corporateName = this.userData.fCustomerName; //公司名
									this.formData.fBusiness = this.userData.fBusiness; //行业性质
									this.formData.fBusinessID = this.userData.fBusinessID + ''; //行业性质ID
									this.formData.fEmail = this.userData.fEmail + ''; //邮箱
									//行政区域数据处理
									if (this.userData.fFullIDPath != '' && this.userData.fFullIDPath != null && this
										.userData.fFullIDPath !=
										undefined) {
										let governmenAreaArr = this.userData.fFullIDPath.split("\\");
										//过滤数组中的空字符
										governmenAreaArr = governmenAreaArr.filter((s) => {
											return s && s.trim();
										});
										this.formData.fGovernmenAreaID = governmenAreaArr
										// console.log('this.formData.fGovernmenAreaID', this.formData.fGovernmenAreaID);
									} else {
										this.hasGovernmenArea = true;
									}
									this.formData.UnifiedCreditCode = this.userData.fIdentityCode; //统一信用代码
									this.formData.fInvoiceClass = this.userData.fInvoiceClassName; //发票类型
									this.formData.fInvoiceClassID = this.userData.fInvoiceClassID + ''; //发票类型ID
									this.fInvoiceClassID = this.userData.fInvoiceClassID + '';
									this.formData.companyNumber = this.userData.fPhone; //公司电话
									this.formData.bankAccount = this.userData.fBankAccounts; //银行卡号
									this.formData.fBankTypeName = this.userData.fBankNames; //开户行
									//地址数据处理
									this.formData.address = this.userData.fAddress;
									this.formData.nameOfLegalPerson = this.userData.fLegalPerson; //法人姓名
									this.formData.IDCardNumber = this.userData.fLegalPersonCard; //法人身份证号
									this.formData.fRemark = this.userData.fRemark; //备注
									this.formData.fInvoiceClass = this.userData.fInvoiceClassName; //发票类型名
									this.formData.finviterCode = this.userData.fInvitationCode; //邀请码
									this.formData.fEmail = this.userData.fEmail; //邮箱
									// console.log(this.formData);
									this.$forceUpdate()
								} else {
									this.hasGovernmenArea = true;
								}
							} else {
								this.hasGovernmenArea = true;
							}
							this.getAdjustBillTypeAnnexRule();
							// this.getAdjustBillTypeAnnexRule1();
						},
						error => {
							this.getAdjustBillTypeAnnexRule();
							// this.getAdjustBillTypeAnnexRule1();
						}
					);
			},
			//获取是否有法人
			getLegalEntity() {
				this.ApiRequestPostNOMess('api/mall/ebcustomer/adjust-bill/get-curret-isshow').then(
					res => {
						if(res.obj.fIsShow == 1){
							this.fIsShow = false
						}else{
							this.fIsShow = true
						}
					}
				);
			},
			//获取验证规则
			getAdjustBillTypeAnnexRule() {
				if(this.getUserInfo.fCommerceTypeID == 2) {
					this.uploadRule = []  //这里请求前先	清除
					this.imgListName = [] //去除图片列表
				}
				this.ApiRequestPostNOMess('api/mall/ebcustomer/adjust-bill/get-enterprise-adjust-bill-type-annex-rule', {
						"id": this.fInvoiceClassID
					})
					.then(res => {
						console.log('获取附件验证规则', res);
						this.uploadRule = res;
						let Token = {};
						let Type = [];
						res.forEach((item, index) => {
							Type[index] = item.rule.fFileType.split(',');
							Token.token = item.token;
							this.uploadToken.push(Token);
						});
						Type.forEach(item => {
							let imitTypeStr = ''
							item.forEach(item1 => {
								imitTypeStr = imitTypeStr + '.' + item1 + ','
							})

						})
						let _this = this
						if (this.userData.files) {

							 this.uploadRule.forEach((item, index) => {
								// console.log(this.userData);
								_this.imgListName[index] = [];
								_this.userData.files.forEach((item1, index1) => {
									if (item.rule.fAnnexTypeID == item1.fAnnexTypeID) {
										_this.imgListName[index].push({
											fPath: item1.fPath,
											ruleId: item.rule.fRuleID
										})
									}
								});
							});
						}
            this.$forceUpdate()
            console.log(this.imgListName)
						this.formLoading = false;
					}, error => {
						this.formLoading = false;
					});
			},
			// //获取验证规则
			// getAdjustBillTypeAnnexRule1() {
			// 	this.ApiRequestPostNOMess('api/mall/ebcustomer/adjust-bill/get-perfect-data-rule', {
			// 			"id": this.fInvoiceClassID
			// 		})
			// 		.then(res => {
			// 			// console.log('获取附件验证规则', res);
			// 			this.uploadRule1 = res;
			// 			let Token = {};
			// 			let Type = [];
			// 			res.forEach((item, index) => {
			// 				Type[index] = item.rule.fFileType.split(',');
			// 				Token.token = item.token;
			// 				this.uploadToken1.push(Token);
			// 			});
			// 			Type.forEach(item => {
			// 				let imitTypeStr = ''
			// 				item.forEach(item1 => {
			// 					imitTypeStr = imitTypeStr + '.' + item1 + ','
			// 				})
			// 				this.limitTypeArr1.push(imitTypeStr)
			// 			})
			// 			let _this = this
			// 			if (this.userData.files) {
			// 				this.uploadRule.forEach((item, index) => {
			// 					// console.log(this.userData);
			// 					_this.userData.files.forEach((item1, index1) => {
			// 						if (item.rule.fAnnexTypeID == item1.fAnnexTypeID) {
			// 							_this.imgListName1[index] = [];
			// 							_this.imgListName1[index].push({
			// 								fPath: item1.fPath,
			// 								ruleId: item.rule.fRuleID
			// 							})
			// 						}
			// 					});
			// 				});
			// 			}
			// 			this.formLoading = false;
			// 		}, error => {
			// 			this.formLoading = false;
			// 		});
			// },
			//获取发票类型
			getInvoiceClass() {
				this.ApiRequestPost('api/mall/ebbase/invoice-class/get-attestation-list', {})
					.then(res => {
						// console.log('获取发票类型', res);
						this.invoiceClassList = res.obj;
						for (let item of this.invoiceClassList) {
							if (item.fInvoiceClassID == 2) {
								this.formData.fInvoiceClass = item.fInvoiceClassName;
								this.formData.fInvoiceClassID = item.fInvoiceClassID;
							}
						}
						this.getUserCurrentBill()
					}, error => {

					});
			},
			//选择发票类型
			selectInvoiceClass(e) {

				this.fInvoiceClassID = e;
				this.getAdjustBillTypeAnnexRule();
				// this.getAdjustBillTypeAnnexRule1();
			},
			//获取验证码
			countDown() {
				this.getYZ = 30;
				this.miao = "S";
				let time = setInterval(() => {
					this.getYZ--;
					if (this.getYZ == 0) {
						clearInterval(time);
						this.miao = "";
						this.getYZ = "获取验证码";
					}
				}, 1000);
			},
			//表单验证
			submitInform(e) {
				this.formData.companyNumber=this.formData.companyNumber.split(/[\t\r\f\n\s]*/g).join('')
				if (e == 2) { //暂存
					let flag1 = true;
					this.$refs.formData.validateField("UnifiedCreditCode", (message) => {
						if (message != '') {
							flag1 = false;
						}
					});
					if (this.formData.fEmail != '') {
						this.$refs.formData.validateField("fEmail", (message) => {
							if (message != '') {
								flag1 = false;
							}
						});
					}
					if (flag1) {
						this.buttonLoadingZC = true;
						this.buttonLoadingTJ = true
						this.Auditing(e)
					} else {
						content.scrollIntoView()
					}
				} else if (e == 1) {
					// console.log(123);
                   let imageList = this.imgListName;
					for (let item of this.uploadRule) {
						// console.log(item.rule.fIsNecessary);
						if (item.rule.fIsNecessary == 1) {
							let flag = {}
							for (let item1 of imageList) {
								if (item1 && item1 != []) {
									flag = item1.find(findItem => findItem.ruleId == item.rule.fRuleID);
									// console.log(flag, 'flag');
									if (flag) {
										break;
									}
								}
							}
							flag = flag ? flag : {};
							if (!flag.ruleId) {
								this.$message({
									message: item.rule.fRuleName + '不能为空',
								});
								return
							}
						}
					}
					this.$refs.formData.validate((valid) => {
						if (valid) {
							this.buttonLoadingTJ = true;
							this.buttonLoadingZC = true
							this.Auditing(e);
						}else {
						let	corporateNameFlag=true //公司名称
						let	UnifiedCreditCodeFlag=true //统一信用代码
						let	fGovernmenAreaIDFlag=true //选择行政区域ID
						let	addressFlag=true //详细地址(住所)
						let	bankAccountFlag=true //银行账号
						let	fBankTypeNameFlag=true //开户行
						let	companyNumberFlag=true//公司电话
						let	fInvoiceClassFlag=true //发票类型
						let	fInvoiceClassIDFlag=true //发票类型ID
						let	finviterCodeFlag=true //邀请码
						let	fEmailFlag=true //邮箱
							this.$refs.formData.validateField("corporateName", (message) => {
								if (message != '') {
									corporateNameFlag = false;
								}
							});
							this.$refs.formData.validateField("UnifiedCreditCode", (message) => {
								if (message != '') {
									UnifiedCreditCodeFlag = false;
								}
							});
							this.$refs.formData.validateField("address", (message) => {
								if (message != '') {
									addressFlag = false;
								}
							});
							this.$refs.formData.validateField("fInvoiceClassID", (message) => {
								if (message != '') {
									fInvoiceClassIDFlag= false;
								}
							});
							this.$refs.formData.validateField("bankAccount", (message) => {
								if (message != '') {
									bankAccountFlag= false;
								}
							});
							this.$refs.formData.validateField("fBankTypeName", (message) => {
								if (message != '') {
									fBankTypeNameFlag= false;
								}
							});
							this.$refs.formData.validateField("companyNumber", (message) => {
								if (message != '') {
									companyNumberFlag= false;
								}
							});
							this.$refs.formData.validateField("fGovernmenAreaID", (message) => {
								if (message != '') {
									fGovernmenAreaIDFlag= false;
								}
							});
							this.$refs.formData.validateField("fEmail", (message) => {
								if (message != '') {
									fEmailFlag= false;
								}
							});
							if(!corporateNameFlag || !UnifiedCreditCodeFlag || !addressFlag || !fInvoiceClassIDFlag 
							){
								content.scrollIntoView({behavior: 'auto',block: 'start',inline: 'nearest'})
							}else if(!bankAccountFlag || !fBankTypeNameFlag || !companyNumberFlag || !fGovernmenAreaIDFlag || !fEmailFlag){
								content.scrollIntoView({behavior: 'auto',block: 'center',inline: 'nearest'})
				
							}
							
						}
					});
				} else {
					let flag1 = true;
					let flag2 = true;
					let flag3 = true;
					this.$refs.formData.validateField("UnifiedCreditCode", (message) => {
						if (message != '') {
							flag1 = false;
						}
					});
					this.$refs.formData.validateField("corporateName", (message) => {
						if (message != '') {
							flag2 = false;
						}
					});
					this.$refs.formData.validateField("fEmail", (message) => {
						if (message != '') {
							flag3 = false;
						}
					});
					if (flag1 && flag2 && flag3) {
						this.buttonLoadingXZ = true;
						this.Auditing(2, true)
					} else {
						if(!flag1 || !flag2){   //统一信用代码或名称时
							content.scrollIntoView()
						}else if(!flag3){
							content.scrollIntoView({behavior: 'auto',block: 'center',inline: 'nearest'})
						}
					}
				}

			},
			//提交审核
			Auditing(type, flag) {
				if (type == 1) {
					//审核
					this.isCommit = true;
				} else {
					//保存
					this.isCommit = false;
				}
				let param = {
					files: [], //图片参数
					fCustomerName: '',
					fGovernmenAreaID: '',
					fIdentityCode: '',
					fBankNames: '',
					fBankAccounts: '',
					fInvoiceClassID: '',
					fPhone: "",
					fAddress: '',
					fAppTypeId: this.fAppTypeID,
					inviterCode: '', //邀请码
					isCommit: this.isCommit,
					fEmail: ''
				};
				param.fCustomerName = this.formData.corporateName ? this.formData.corporateName : '';
				param.fGovernmenAreaID = this.formData.fGovernmenAreaID[this.formData.fGovernmenAreaID.length - 1];
				param.fGovernmenAreaID = param.fGovernmenAreaID ? param.fGovernmenAreaID : -1
				param.fIdentityCode = this.formData.UnifiedCreditCode ? this.formData.UnifiedCreditCode : '';
				param.fInvoiceClassID = this.formData.fInvoiceClassID ? this.formData.fInvoiceClassID : '';
				param.fAddress = this.formData.address;
				param.fBankNames = this.formData.fBankTypeName;
				param.fPhone = this.formData.companyNumber ? this.formData.companyNumber : '';
				param.fBankAccounts = this.formData.bankAccount;
				param.inviterCode = this.formData.finviterCode ? this.formData.finviterCode : '';
				param.fEmail = this.formData.fEmail ? this.formData.fEmail : '';
				for (let item of this.imgListName) {
					if (item == null) {
						item = [];
					}
					for (let items of item) {
						if (items && items != []) {
							let arr = items.fPath.split('/')
							let imgData = {
								fFileName: items.fSourceFileName ? items.fSourceFileName : arr[arr.length-1],
								fPath: arr[arr.length-1],
								ruleId: items.ruleId
							}
							param.files.push(imgData)
						}
					}
				}
				console.log(param);
				this.isEdit = false
				this.ApiRequestPostNOMess('api/mall/ebcustomer/adjust-bill/create-or-update-enterprise', param)
					.then(res => {
						// console.log(JSON.stringify(res));
						if (flag) {
							this.GeneratePowerOfAttorney(param)
						} else {
							this.$message({
								message: res.message,
								type: 'success'
							});
							let _this = this
							if (_this.isCommit) {
								//跳转到我的资料页面
								// setTimeout(() => {
								// 	_this.$router.replace({
								// 		path: "/PersonalContent/myStuff",
								// 	});
								// }, 2000);
								//跳转到审核中页面
								setTimeout(() => {
									_this.$router.replace({
										path: "/UnderReview",
										query: {
											type: 2
										}
									});
									this.buttonLoadingZC = false;
									this.buttonLoadingTJ = false;
									this.isEdit = true
								}, 2000);
							} else {
								this.buttonLoadingZC = false;
								this.buttonLoadingTJ = false
								this.isEdit = true
							}
							
						}
					}, error => {
						this.isEdit = true
						this.buttonLoadingZC = false;
						this.buttonLoadingTJ = false;
						this.buttonLoadingXZ = false;
					});
			},
			//下载授权委托书
			GeneratePowerOfAttorney(param) {
				let param1 = {
					fEnterprise: param.fCustomerName,
					fAddress: param.fAddress,
				}
				this.ApiRequestPost('api/mall/ebcustomer/baseinfo/down-power-of-attorney', param1)
					.then(res => {
						// console.log(res);
						if (res.obj) {
							window.open(res.obj, "_blank");
						}
						this.buttonLoadingXZ = false;
						this.isEdit = true
					}, error => {
						this.buttonLoadingXZ = false;
						this.isEdit = true
					});
			},
			//限制银行账号输入
			bankAccountReplace() {
				this.formData.bankAccount = this.formData.bankAccount.replace(/[\W]/g, '');
			},
			//限制公司电话输入
			companyNumberReplace() {
				// this.formData.companyNumber = this.formData.companyNumber.replace(/^(\(\d{3,4}\)|\d{3,4}-)?\d{7,8}$/, '');
			}
		}
	}
</script>

<style lang="scss">
  .trueClass {
     width: 480px;
     margin-left: 0px;
     position: absolute;
     top: 35px;
     left: 0px
  }
  .rulecontent{
    .el-form-item__label{
      text-align: left;
      margin-left: 6px;
      line-height: 24px;
    }

  }
  .manClass {
    width: 480px;
    margin-left: 0px;
    position: absolute;
    top: 450px;
    left: 0px;
  }
	.border-theme {
		@include themify($themes) {
			border: 1px solid themed('themes_color');
		}
	}

	.enterprise {


		.color-theme {
			@include themify($themes) {
				color: themed('themes_color');
			}
		}


		.background-color-theme {
			@include themify($themes) {
				background-color: themed("themes_color");
				color: themed("text_color_white");
			}
		}

		.PersonanProfile-Number {
			font-size: 12px;
			color: #0173fe;
		}

		.upload-box {
			width: 331px;
			height: 117px;
			border-radius: 4px;
			border: 1px solid rgba(228, 228, 228, 1);
		}

		.contBox {
			width: 100%;
		}

		.demo-ruleForm {
			width: 472px;
			margin: 0px auto;
			margin-left: 200px;
			position: relative;
			z-index: 1;
		}

		.newPersonbtn-title1 {
			width: 110px;
			height: 30px;
			background-color: #0173FE;
			border-radius: 2px;
			color: #FFFFFF;
			line-height: 30px;
			text-align: center;
			// float:right;
		}

		.newPersonbtn-Boxx {
			display: flex;
			justify-content: space-between;
			margin-right: 20px;
		}

		::v-deep .el-upload--picture-card {
			width: 97px;
			height: 97px;
			line-height: 97px;
			margin: 10px 0px 0px 10px;
		}

		::v-deep .el-upload-list--picture-card .el-upload-list__item {
			width: 97px;
			height: 97px;
			margin: 10px 0px 0px 10px;
		}

		.newPersonbtn-Box {}

		.bg-box {
			position: relative;
			z-index: 0;
			width: 100%;
			height: 230px;
			background: url(../../assets/imgs/u305.png) no-repeat;
			background-size: 911px 310px;
			background-position: right bottom;
			-webkit-filter: blur(1px);
			margin-top: -105px;
			opacity: 25%;
		}

		.newPersonbtn-title {
			width: 73px;
			height: 22px;
			color: #000000;
			padding: 8px 13px;
		}

		.el-button {
			width: 160px;
			height: 40px;
		}

		.el-input__inner,
		.el-select {
			width: 330px !important;
			height: 32px;
			line-height: 32px;
		}

		.areaBox {
			position: relative;
		}

		.areaBox::v-deep .el-cascader {
			width: 330px;
		}

		.rule-name::v-deep .el-form-item__label {
			line-height: normal;
		}

		// .uploadimglist {
		// 	border: 1px solid #d0ebee;
		// 	border-radius: 4px;
		// }
		.info-top {
			min-width: 525px;

			background: inherit;
			background-color: rgba(255, 255, 255, 0);
			box-sizing: border-box;
			border-width: 1px;
			border-style: solid;
			border-color: rgba(255, 204, 0, 1);
			border-radius: 5px;
			border-bottom-right-radius: 0px;
			border-bottom-left-radius: 0px;
			-moz-box-shadow: none;
			-webkit-box-shadow: none;
			box-shadow: none;

		}

		.el-cascader {
			width: 328px;
		}

		.infoti {
			margin-bottom: 20px;
			font-size: 13px;
			// width: 598px;
			height: 27px;
			line-height: 27px;
			background: inherit;
			background-color: rgba(255, 250, 238, 1);
			border: none;
			border-radius: 5px;
			border-bottom-right-radius: 0px;
			border-bottom-left-radius: 0px;
			-moz-box-shadow: none;
			-webkit-box-shadow: none;
			box-shadow: none;
			color: #FFCC00;
			text-align: left;
		}
	}

	.background-color-theme {
		@include themify($themes) {
			background-color: themed("themes_color");
			color: themed("text_color_white");
		}
	}
	.fapiaoType{
		width: 160px;
		height: 30px;
		border:1px solid #F2F2F2;
		font-size: inherit;
		line-height: 30px;
		text-align: center;
		cursor: pointer;
		border-radius: 4px;
		font-weight: 400;
		color: #999999;
	}
	.fapiaoType:first-child{
		 margin-right: 10px;
	}
	.colortype{
		border: 2px  solid #0173FE;
	}
	.gouhao{
			position: absolute;
			bottom: 0;
			right: -1px;
			display: inline-block;
			height:0;
		    width:0;
		    overflow: hidden;
		    font-size: 0;
		    line-height: 0;
		    border-color:transparent #0173FE  transparent transparent;
		    border-style:solid solid dashed dashed;
		    border-width:15px 16px 0 0 ;
	}
	.icongouhao{
		position: absolute;
		bottom: 6px;
		right: 0px;
		width: 12px;
		height: 12px;
		z-index:666;
	}
</style>
